<template>
  <el-dialog :visible.sync="visible" :title="title" custom-class="v-dialog v-dialog--xs v-dialog--footer v-dialog--mdpadding" :close-on-click-modal="false"  @closed="cancel" center>
    <el-upload
      v-if="!fileList.length"
      class="upload-demo"
      :multiple="false"
      drag
      :on-remove="() => fileList.splice()"
      :file-list="fileList"
      :on-change="fileChange"
      accept="image/png, image/jpeg"
      :action="`${POST_URL}?loginKey=${loginInfo.loginKey}&userid=${loginInfo.UserID}&type=image`">
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">{{$t('userCenter.changeLogoTip')}}<em>{{$t('userCenter.clickUpload')}}</em></div>
    </el-upload>
    <div class="image" v-else>
      <img :src="fileList[0].url"/>
      <el-button type="text" icon="el-icon-circle-close" @click="change">{{$t('userCenter.change')}}</el-button>
    </div>
    <div slot="footer">
      <el-button @click="cancel" size="medium">{{$t('common.cancel')}}</el-button>
      <el-button @click="confirm" :disabled="!filePath" type="primary" size="medium" v-ripple>{{$t('common.save')}}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { POST_URL, DOWNLOAD_URL } from '@/api/config'
import { mapGetters } from 'vuex'
export default {
  props: {
    title: {
      type: String,
      default: '修改logo'
    },
    downloadUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      resolve: null,
      reject: null,
      POST_URL,
      DOWNLOAD_URL,
      filePath: '',
      fileList: [],
    }
  },
  computed: {
    ...mapGetters([
      'loginInfo'
    ])
  },
  methods: {
    import() {
      this.visible = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    cancel() {
      this.reject(false)
      this.fileList.splice(0)
      this.filePath = ''
      this.visible = false
    },
    confirm() {
      this.resolve({
        path: this.filePath,
        url: this.fileList[0].url
      })

      this.visible = false
    },
    change() {
      this.fileList.splice(0)
    },
    fileChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = fileList.slice(-1)
        this.filePath = fileList[0].response
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.upload-demo {
  text-align: center;
  & ::v-deep .el-upload-dragger {
    width: 350px;
    height: 175px;
  }
}
.image {
  width: 350px;
  height: 178px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 40px;
    height: 40px;
    outline: 1px dashed #DCDFE6;
  }
  .el-button {
    margin-top: 12px;
  }
}
</style>
